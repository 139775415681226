import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;